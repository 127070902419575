$(function () {
	APP.init();
	lozadInit();
	fancyboxInit();
	tocInit();

	accordionInit();
	tabsletControlInit();
	toggleShowHidePassword();

	filterInit();
	countUpInit();
	showFormHidden();
});

$(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = $("header"),
	body = $("body"),
	headerSite = $("header .header-site"),
	headerSearch = $("header .header-search"),
	backdrop = $(".backdrop"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	buttonSearch = $(".button-search"),
	buttonWishlist = $(".button-wishlist"),
	mobileWrap = $(".mobile-wrap"),
	heightHeader = $("header").height(),
	heightHeaderTop = $("header .header-top").outerHeight(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		// header
		$(window).scrollTop() > heightHeaderTop
			? headerSite.addClass("active")
			: headerSite.removeClass("active");
	},
	backdrop: () => {
		backdrop.on('click', function () {
			$(this).removeClass('open');
			$("#buttonMenu").removeClass('open'); // remove button menu header
			$(".search-wrap").removeClass('open'); // remove search header
			mobileWrap.removeClass('open'); // remove menu header
		});
	},
	backToTop: () => {
		backToTop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	mapping: () => {
		// const mainMenu = $("header .header-left .navbar-nav").mapping({
		// 	mobileWrapper: ".mobile-wrap .navbar-nav-list",
		// 	mobileMethod: "prependTo",
		// 	desktopWrapper: "header .header-left",
		// 	desktopMethod: "prependTo",
		// 	breakpoint: 1279.98,
		// });
	},
	toggleAccount: () => {
		// toggle account
		$(".button-account").on("click", function () {
			$(".header-account").toggleClass("active");
		});
	},
	toggleMenu: () => {
		$(".button-menu").on("click", function () {
			$(this).toggleClass("active");
			$(".main-navigation").toggleClass("active");
		});
		$(".main-menu .has-children .icon-children").on("click", function () {
			$(this).toggleClass("active");
			$(this).prev().slideToggle();
		});
	},
	toggleSearch: () => {
		// toggle search
		buttonSearch.on("click", function () {
			headerSearch.toggleClass("active");
		});
		$('.search-icon-close').on("click", function () {
			headerSearch.removeClass("active");
		});
	},
	toggleCart: () => {
		$('.cart .icon-cart').on('click', function () {
			$('.cart .widget-shopping-cart').toggleClass('active')
		})
	},
	closeCart: () => {
		$('.cart .btn-close').on('click', function () {
			$('.cart .widget-shopping-cart').removeClass('active')
		})
	},
	spinInput: () => {
		$(".spin-btn").length > 0 &&
			$(".spin-btn").on('click', function () {
				var e = $(this),
					i = $(this).data("spin"),
					n = e.parent().find("input").val();
				if ("inc" == i) var t = parseFloat(n) + 1;
				else if (n > 0) t = parseFloat(n) - 1;
				else t = 0;
				e.parent().find("input").val(t);
		});
	},
	init: () => {
		APP.backdrop();
		APP.backToTop();
		APP.mapping();
		// APP.toggleLanguage();
		// APP.toggleAccount();
		APP.toggleAccount();
		APP.toggleMenu();
		APP.toggleCart();
		APP.closeCart();
		APP.spinInput();
		APP.toggleSearch();
	}
}

// const homeBannerSlider = new Swiper(".home-banner-section .swiper", {
// 	loop: true,
// 	autoplay: {
// 		delay: 5000,
// 		disableOnInteraction: false,
// 		pauseOnMouseEnter: true,
// 	},
// 	// modules: [SwiperGL],
// 	// effect: 'gl',
// 	// gl: {
// 	// 	shader: 'random',
// 	// },
// 	// shuttersEffect: {
// 	// 	split: 5,
// 	// },
// 	speed: 1000,
// 	observer: true,
// 	observeParents: true,
// 	lazy: {
// 		loadPrevNext: true,
// 	},
// 	navigation: {
// 		nextEl: ".home-banner-section .button-next",
// 		prevEl: ".home-banner-section .button-prev",
// 	},
// 	pagination: {
// 		el: ".home-banner-section .swiper-pagination",
// 		clickable: true,
// 	},
// 	// breakpoints: {
// 	// 	768: {
// 	// 		slidesPerView: 2,
// 	// 	},
// 	// 	1024: {
// 	// 		slidesPerView: 3,
// 	// 	},
// 	// 	1280: {
// 	// 		slidesPerView: 4,
// 	// 	},
// 	// },
// });

const homeIconSlider = new Swiper(".home-icon-swiper .swiper", {
	speed: 1000,
	observer: true,
	observeParents: true,
	slidesPerView: 2.5,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-icon-swiper .button-next",
		prevEl: ".home-icon-swiper .button-prev",
	},
	pagination: {
		el: ".home-icon-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 3,
		},
		768: {
			slidesPerView: 4,
		},
		1024: {
			slidesPerView: 5,
		},
		1280: {
			slidesPerView: 6,
		},
	},
});

const homeReviewSlider = new Swiper(".home-review-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-review-swiper .button-next",
		prevEl: ".home-review-swiper .button-prev",
	},
	pagination: {
		el: ".home-review-swiper .swiper-pagination",
		clickable: true,
	},
});

const homeProductSlider = new Swiper(".home-product-swiper .swiper", {
	// loop: true,
	// autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	speed: 1000,
	observer: true,
	observeParents: true,
	spaceBetween: 15,
	slidesPerView: 1.5,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-product-swiper .button-next",
		prevEl: ".home-product-swiper .button-prev",
	},
	pagination: {
		el: ".home-product-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2.5,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1280: {
			slidesPerView: 4,
			spaceBetween: 30,
			grid: {
				fill: 'row',
				rows: 2,
			}
		},
	},
});


function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		enableAutoReload: true,
	});
	// data-background-image="image.png"
	const backgroundObserver = lozad(".lozad-bg", {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
		autoFocus: false,
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});

	// disable  drag fancybox in contact
	Fancybox.bind(".contact-page [data-fancybox]", {
		dragToClose: false,
	});
}

function tocInit() {
	$("#toc").toc({ content: "div.full-content", headings: "h2,h3,h4" })
}

function countUpInit() {
	let targetItem = $('.countup-item');
	const options = {
		separator: '.',
		enableScrollSpy: true, // if true -> turn off countUp.start() after first scroll spy check
		scrollSpyDelay: 500, // delay for scroll spy check
		scrollSpyOnce: true,
	};
	if (targetItem) {
		targetItem.each(function (index) {
			let endVal = $(targetItem[index]).data('count');
			let countUp = new CountUp(targetItem[index], endVal, options);
			if (!countUp.error) {
			} else {
				console.error(countUp.error);
			}
		});
	}
	// let demo = new CountUp(targetItem, endVal, { enableScrollSpy: true });
}

function accordionInit() {
	$('.accordion-item').on('click', function () {
		$(this)
			.toggleClass('active')
			.siblings().removeClass('active')
		$(this)
			.find('.accordion-body').slideToggle()
			.parents('.accordion-item')
			.siblings().find('.accordion-body').slideUp()
	});
	$('.accordion-item:first-child .accordion-body').slideDown()
}

function tabsletControlInit() {
	$('.tabslet-tabs li').on('click', function () {
		$('.caracole-group .caracole-item').eq($(this).index()).addClass('active').siblings().removeClass('active');
	})
}

function filterInit() {
	$('.filter-toggle').on('click', function () {
		$('.product-filter-wrap').toggleClass('active');
		$('.bg-overlay').toggleClass('active');
	});
	$('.bg-overlay, .btn-close-filter, .apply-filter').on('click', function () {
		$('.product-filter-wrap').removeClass('active');
		$('.bg-overlay').removeClass('active');
	});

	$('.filter-group .filter-head .filter-icon-toggle').on('click', function () {
		$(this).parent().toggleClass('active').next().slideToggle();
	});
	$('.filter-menu .has-children .children-icon-toggle').on('click', function () {
		$(this).next().slideToggle().parent().toggleClass('active').siblings().removeClass('active').find('.children-icon-toggle').next().slideUp();
	});
}

function toggleShowHidePassword(){
	$('.input-pwd-reveal').each(function(){
		let input = $(this).find('input')
		let btnToggle = $(this).find('.reveal-password');
		btnToggle.on('click', function(){
			let checkType = input.attr('type');
			$(this).toggleClass('active')
			if (checkType == 'Password'){
				input.attr('type', 'text')
			}
			else if (checkType == 'text'){
				input.attr('type', 'Password')
			}
		})
	})
}

function showFormHidden() {
	$(".form-checkbox-wrap .checkbox label#sameinfo").length > 0 &&
		$(".form-checkbox-wrap .checkbox label#sameinfo").on("click", function () {
			$("#ttmh:checked").length > 0
				? $(".cart-form .form-hidden#sameinfoform").slideDown()
				: $(".cart-form .form-hidden#sameinfoform").slideUp();
		}),
		$(".form-checkbox-wrap .checkbox label#exportbill").length > 0 &&
		$(".form-checkbox-wrap .checkbox label#exportbill").on(
			"click",
			function () {
				$("#Invoice_Required:checked").length > 0
					? $(".cart-form .form-hidden#exportbillform").slideUp()
					: $(
						".cart-form .form-hidden#exportbillform"
					).slideDown();
			}
		);
}
